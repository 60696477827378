import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { AiFillPhone, AiOutlineArrowDown, AiOutlineArrowLeft, AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { DatePicker } from 'antd';
import _ from "lodash";
import moment from "moment";

import "firebase/database";
import "firebase/messaging";
import { firebaseClient } from "../../firebase";

import {
    CallIcon,
    EditIcon,
    OpenCaptionIcon,
    ThumbsDownIcon,
    ThumbsUpIcon,
} from "@100mslive/react-icons";

import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    IconButton,
    Tag,
    Text,
    useDisclosure,
    List,
    ListItem,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Input,
    InputLeftElement,
    InputGroup,
    VStack,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    Divider,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Avatar,
    Select,
    InputRightElement,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Icon,
    Progress,
    FormLabel,
    Image,
    useToast,
    toast,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from "@chakra-ui/react";

import {
    ArrowDownIcon,
    ArrowUpIcon,
    ChevronRightIcon,
    CloseIcon,
    Search2Icon,
    ChevronDownIcon,
    PhoneIcon,
    CopyIcon,
    CheckCircleIcon,
    ViewIcon,
    ViewOffIcon,
    ChatIcon,
    AddIcon,
    DeleteIcon,
    HamburgerIcon,
} from "@chakra-ui/icons";
import { TbDotsVertical } from "react-icons/tb";

import { Box, Grid, GridItem, Heading } from "@chakra-ui/layout";

import {
    closeDoubtAction,
    getAllTeachers,
    getBatchSubjectStatusAction,
    getChatDetailsAction,
    getDoubtsAction,
    getTeacherAssingedAction,
    getTeacherDoubtsAction,
    publicDoubtAction,
    readCommentAction,
    resetReadAction,
    resetTeacherAssignedData,
    resetUpdateDoubtStatus,
    updateDoubtAction,
} from "../../redux/reducers/doubt";

import { STATUS } from "../../Constants";
import { apis } from "../../services/api/apis";
import {
    bilingualText,
    mobileView,
    useCustomToast,
    webView,
} from "../../utils/Helper";

import { DiscussionComments } from "../../routes/DiscussionCommunity/DiscussionComments";
import { getYouTubeVideoStatsAction } from "../../redux/reducers/user";
import PollCreateForm from "./PollCreateForm";
import { resetFileAction, uploadFileAction } from "../../redux/reducers/website";

export const TeacherChatScreen = (props) => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

    const [active, setActive] = useState();
    const [formKey, changeFormKey] = useState(0);
    const [currentTab, setCurrentTab] = useState("0");
    const [searchDoubt, changeSearchDoubt] = useState();
    const [currentTeacher, setCurrentTeacher] = useState();
    const [testDiscussion, openTestDiscussion] = useState();
    const [courseDiscussion, openCourseDiscussion] = useState();
    const [selectedClasEnd, setSelectedClasEnd] = useState([]);

    const {
        user,
        chatDetails,
        allTeachers,
        teacherDoubts,
        closeDoubtStatus,
        getBatchSubjectLectureStatus,
        youtubeVideoStats,
    } = useSelector((s) => ({
        user: s.user.user,
        // pageConfig: s.doubts.pageConfig,
        chatDetails: s.doubts.chatDetails,
        allTeachers: s.doubts.allTeachers,
        teacherDoubts: s.doubts.teacherDoubts,
        closeDoubtStatus: s.doubts.closeDoubtStatus,
        // getChatDetailsStatus: s.doubts.getChatDetailsStatus,
        // teacherAssignedData: s.doubts.teacherAssignedData || [],
        getBatchSubjectLectureStatus: s.doubts.getBatchSubjectLectureStatus,
        youtubeVideoStats: s.user.youtubeVideoStats?.statistics,
    }));

    useEffect(() => {
        dispatch(getChatDetailsAction({ teacherId: user?._id }));
    }, [currentTab]);

    useEffect(() => {
        if (getBatchSubjectLectureStatus === "SUCCESS") {
            dispatch(getChatDetailsAction({ teacherId: user?._id }));
        }
    }, [getBatchSubjectLectureStatus]);

    useEffect(() => {
        if (allTeachers?.length && params.staffId) {
            const staff = _.find(allTeachers, (t) => t.user?._id === params.staffId);
            setCurrentTeacher(staff);
        }
    }, [allTeachers, params]);

    const checkRole = useMemo(() => {
        return (
            user?.staff?.staffRole === "ADMIN" ||
            user?.staff?.staffRole === "HEAD_TEACHER"
            // user?.staff?.staffRole === "TEACHER"
        );
    }, [user?.staff?.staffRole]);

    useEffect(() => {
        if (params?.staffId)
            dispatch(getTeacherAssingedAction({ assignedTo: params.staffId }));
        else if (user?.staff)
            dispatch(getTeacherAssingedAction({ assignedTo: user._id }));
        else dispatch(resetTeacherAssignedData());
    }, [teacherDoubts, dispatch, user, params]);

    const getDoubts = useCallback(() => {
        if (params.page)
            if (params.staffId) {
                dispatch(
                    getTeacherDoubtsAction({
                        page: params.page,
                        staff: params.staffId,
                        doubt_text_regex: searchDoubt,
                    })
                );
            } else
                dispatch(
                    getTeacherDoubtsAction({
                        page: params.page,
                        staff: user?._id,
                        doubt_text_regex: searchDoubt,
                    })
                );
    }, [params.page, params.staffId, dispatch, searchDoubt, user?._id]);

    const getTeachers = useCallback(() => {
        if (checkRole) dispatch(getAllTeachers());
    }, [dispatch, checkRole]);

    useEffect(() => {
        getDoubts();
    }, [getDoubts]);

    useEffect(() => {
        getTeachers();
    }, [getTeachers]);

    useEffect(() => {
        if (closeDoubtStatus === STATUS.SUCCESS && active) {
            setActive(_.find(teacherDoubts, (d) => d._id === active._id));
        }
    }, [closeDoubtStatus, teacherDoubts, active]);

    useEffect(() => {
        if (chatDetails?.Live?.length > 0 && currentTab === "0") {
            dispatch(
                getYouTubeVideoStatsAction({
                    video_value: chatDetails?.Live[0].youtubeVideoId,
                })
            );
        }
    }, [chatDetails]);

    const _openDoubt = (a) => {
        setActive(a);
        onOpen();
    };

    const handlePageChange = (page) => {
        if (checkRole) history.push(`/doubts/${page}/${params.staffId}`);
        else history.push("/doubts/" + page);
    };

    const handleChangeSearch = (e) => {
        changeSearchDoubt(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        handlePageChange(1);
    };

    const resetSearch = () => {
        changeSearchDoubt(null);
        changeFormKey((d) => d + 1);
        handlePageChange(1);
    };

    const handleTabChange = (e) => {
        const value = e.target.value;
        if (value === "0" && chatDetails?.Live?.length > 0) {
            dispatch(
                getYouTubeVideoStatsAction({
                    video_value: chatDetails?.Live[0].youtubeVideoId,
                })
            );
        }
        setCurrentTab(value);
    };

    const handleOpenTestDiscussion = (test) => {
        openTestDiscussion((d) => (d ? null : test));
    };

    const handleOpenCourseDiscussion = (content) => {
        openCourseDiscussion((d) => (d ? null : content));
    };


    // const endSelectedClass = () => {
    //    dispatch(getBatchSubjectStatusAction({ id: selectedClasEnd, status: "Ended" }));
    //    setSelectedClasEnd([]);
    // };

    return (
        <Box
            className="chatparee"
            style={{
                height: "91.8vh",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box className="bottomHeader">
                <div
                    className="row align-items-center ms-1 mx-0 px-0"
                    style={{ overflow: "overflow-hidden" }}
                >
                    <div
                        className="col-xl-2 col-lg-2 col-md-3 col-sm-3 
                            d-flex justify-content-between align-items-center"
                    >
                        <Image
                            src={"/images/teacher-chatroom-icon.png"}
                            width="12%"
                            alt=""
                        />
                        <Heading fontSize="16px" color="#797DFA" width="auto">
                            Teachers chatrooms
                        </Heading>
                    </div>
                    <div
                        style={{
                            backgroundColor: "#797DFA",
                            color: "#FFFFFF",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                        }}
                        className="col-xl-5 col-lg-5 col-md-6 col-sm-6 
                   d-flex justify-content-between align-items-center"
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ViewIcon fontSize="sm" boxSize={5} mr={2} />
                            <Text fontSize="sm">Views :</Text>
                            <Text fontSize="sm" ml={2}>
                                {2}
                            </Text>
                        </div>
                        <Box width="1px" height="15px" backgroundColor="#C3C3C3" />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ThumbsUpIcon fontSize="sm" mr={2} />
                            <Text fontWeight="sm" fontSize="sm">
                                Likes:
                            </Text>
                            <Text fontSize="sm" ml={2}>
                                {0}
                            </Text>
                        </div>
                        <Box width="1px" height="15px" backgroundColor="#C3C3C3" />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ChatIcon fontSize="sm" mr={2} />
                            <Text fontWeight="sm" fontSize="sm">
                                Comments:
                            </Text>
                            <Text fontSize="sm" ml={2}>
                                {0}
                            </Text>
                        </div>
                        <Box width="1px" height="15px" backgroundColor="#C3C3C3" />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <AiOutlineHeart fontSize="sm" />
                            <Text fontWeight="sm" fontSize="sm" marginLeft="5px">
                                Favorite:
                            </Text>
                            <Text fontSize="sm" ml={2}>
                                {0}
                            </Text>
                        </div>
                    </div>
                    {/* {checkRole && (
                  <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                     <Box {...webView} flex={3}>
                        <MemorizeTeachersList resetSearch={resetSearch} />
                     </Box>
                  </div>
               )}
               <div className="col-xl-1 col-lg-1 col-md-2 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                  <Flex>
                     <Box fontSize={[14]}>Teachers: &nbsp;</Box>
                     <Box color={"brand.blue"} fontWeight={"bold"} fontSize={[16]}>
                        {allTeachers?.length || 0}
                     </Box>
                  </Flex>
               </div>
               {
                  currentTab === "0" && youtubeVideoStats !== undefined &&
                  <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                     <Flex>
                        <Box fontSize={[14]}>Views: &nbsp;</Box>
                        <Box color={"brand.blue"} fontWeight={"bold"} fontSize={[16]}>
                           {youtubeVideoStats?.viewCount || 0}
                        </Box>
                     </Flex>
                  </div>
               }
               <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 mb-xl-0 mb-lg-0 mb-md-2 mb-2 text-end position-relative">
                  {currentTab == "0" && chatDetails && chatDetails.Live.length !== 0 ?
                     <button
                        onClick={() => handleLiveChange(chatDetails)}
                        className="btn btn-secondary position-static"
                        style={{ height: "auto", padding: "7px 10px" }}
                     >
                        End All
                     </button> : <></>}
               </div>*/}

                    <div className="col-xl-5 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-3 mb-3">
                        <Box w={["100%", "100%", "100%", "100%"]} display="flex">
                            {(currentTab === "0" && chatDetails?.Live) && <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                <Text 
                                fontSize="16px"
                                noOfLines={1}
                                overflow="hidden"
                                textOverflow="ellipsis">Lecture : {chatDetails?.Live?.[0]?.lectureName}</Text></div>}
                            <Select
                                borderColor={["#C1C2C2"]}
                                value={currentTab}
                                onChange={handleTabChange}
                                placeItems={"Select appointment status"}
                                style={{ cursor: "pointer" }}
                            >
                                <option value={0}>Live</option>
                                <option value={1}>History</option>
                                <option value={2}>Upcoming</option>
                            </Select>
                        </Box>
                    </div>
                </div>
            </Box>
            {/* {checkRole && (
            <>
              <MobileTeacherList resetSearch={resetSearch} />
            </>
          )} */}
            <Box style={{ height: "calc(100% - 49px" }} className="" mt={0}>
                {currentTab === "0" && (
                    <div style={{ height: "100%" }}>
                        {chatDetails?.Live?.length > 0 ? (
                            <LiveChatList
                                key={0}
                                liveMessages={chatDetails.Live}
                                setSelectedClasEnd={setSelectedClasEnd}
                                selectedClasEnd={selectedClasEnd}
                                youtubeVideoId={chatDetails?.youtubeVideoId}
                                allTeachers={allTeachers}
                            />

                        )
                            : <div style={{ background: "#fff", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", fontSize: "20px", color: "#00000070" }}>
                                <Image src="/images/no_class.webp" width={"28%"} />
                            </div>
                        }
                    </div>
                )}
                {currentTab === "1" && <HistoryList chatDetails={chatDetails} />}
                {currentTab === "2" && (
                    <HStack alignItems={"stre"} width={"100%"}>
                        <Box flex={4}>
                            <UpcomingList chatDetails={chatDetails} handleTabChange={handleTabChange} />
                        </Box>
                    </HStack>
                )}
            </Box>
        </Box>
    );
};

const LiveChatList = ({
    allTeachers,
    liveMessages,
    setSelectedClasEnd,
    selectedClasEnd,
    youtubeVideoId,
    pollRoomId,
}) => {
    const dispatch = useDispatch();
    const chatContainerRef = useRef(null);
    const [msgId, setMsgId] = useState("");
    const [replyMessage, setReplyMessage] = useState("");
    const [mentorMessage, setMentorMessages] = useState("");
    const [replyModalOpen, setReplyModalOpen] = useState(false);
    const [liveChatMessages, setLiveChatMessages] = useState([]);
    const [livePolls, setLivePolls] = useState([]);
    // const [youtubeVideoData, setYoutubeVideoData] = useState(null);  
    const [filtredPoll, setFilteredPoll] = useState([]);
    const [sortedChats, setSortedChats] = useState([]);
    const [pollDoubtTab, setPollDoubtTab] = useState("Poll");
    const [selectedChatDetails, setSelectedChatDetails] = useState({});
    const [allLivePollRoomIds, setAllLivePollRoomIds] = useState(new Set());
    const {
        user,
        getBatchSubjectLectureStatus,
        youtubeVideoStats,
        youtubeVideoStatsStatus,
    } = useSelector((s) => ({
        user: s.user.user,
        youtubeVideoStatsStatus: s.user.youtubeVideoStatsStatus,
        youtubeVideoStats: s.user.youtubeVideoStats?.statistics,
        getBatchSubjectLectureStatus: s.doubts.getBatchSubjectLectureStatus,
    }));

    useEffect(() => {
        let totalLivePollIds = new Set();

        liveMessages.map((chat) => {
            const chatRoomId = chat?.chatRoomId;
            const pollRoomId = chat?.pollRoomId;
            const chatRoomRef = firebaseClient.database().ref(chatRoomId);

            chatRoomRef.on("value", (snapshot) => {
                const chatData = snapshot.val();
                if (chatData) {
                    const chatMessages = Object.values(chatData);
                    const validMessages = chatMessages.reduce((acc, msg) => {
                        if (typeof msg === "object" && msg !== null) {
                            acc.push({
                                ...msg,
                                chatRoomId: chat?.chatRoomId,
                                batch: chat?.batch,
                            });
                        }
                        return acc;
                    }, []);

                    setLiveChatMessages((prev) => [...prev, ...validMessages]);
                    if (chatContainerRef.current) {
                        chatContainerRef.current.scrollTop =
                            chatContainerRef.current.scrollHeight;
                    }
                }
            });
            totalLivePollIds.add(pollRoomId)
        });

        for (const pollRoomId of totalLivePollIds) {
            getPollChatDetails(pollRoomId);
        }
        setAllLivePollRoomIds(totalLivePollIds)
    }, [liveMessages]);

    const getPollChatDetails = (pollRoomId) => {
        const chatRoomRef = firebaseClient.database().ref(pollRoomId);
        chatRoomRef
            .once("value")
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const pollData = Object?.keys(data);
                    const pollDetails = data?.[pollData[0]]?.pollDetails;

                    if (pollDetails?.length > 0) {
                        setLivePolls((prev) => [...prev, ...pollDetails]);
                    }
                } else {
                    toast({
                        status: "warning",
                        title: "First Add Poll to see poll list",
                        position: "top",
                    });
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        if (liveChatMessages?.length > 0) {
            const uniqueData = Object.values(
                liveChatMessages.reduce((acc, item) => {
                    acc[item._id] = item;
                    return acc;
                }, {})
            );
            const sortedLiveChatMessages = uniqueData.sort(
                (a, b) =>
                    new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
            );
            setSortedChats(sortedLiveChatMessages);
        }
    }, [liveChatMessages]);

    useEffect(() => {
        if (getBatchSubjectLectureStatus === "SUCCESS") {
            dispatch(getChatDetailsAction({ teacherId: user?._id }));
        }
    }, [getBatchSubjectLectureStatus]);

    useEffect(() => {
        if (livePolls?.length > 0) {
            let uniqueFilteredPolls = livePolls?.reduceRight((acc, currentItem) => {
                if (!acc.some((item) => item?._id === currentItem?._id)) {
                    acc.push(currentItem);
                }
                return acc;
            }, []);

            uniqueFilteredPolls = uniqueFilteredPolls?.filter((d) => d?._id);

            setFilteredPoll(uniqueFilteredPolls);
        }
    }, [livePolls]);

    const handleEndClass = (e) => {
        dispatch(getBatchSubjectStatusAction({ id: [chat?._id], status: "Ended" }));
    };

    const endSelectedClass = (classId) => {
        setSelectedClasEnd((prev) => {
            if (prev.includes(classId)) {
                return prev.filter((id) => id !== classId);
            } else {
                return [...prev, classId];
            }
        });
    };

    const handleMentorMessages = () => {
        if (mentorMessage.trim()) {
            const newMessage = {
                replies: [],
                text: mentorMessage,
                timestamp: moment().format(),
                _id: moment().unix() + Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000,
                user: {
                    avatar: user?.avatar,
                    name: user?.name || "Mentor",
                    id: user?.staff?._id,
                },
                isMentorMessage: true,
            };
            handleRoomIdsMessages(newMessage);
            setMentorMessages("");
        }
    };

    const handleRoomIdsMessages = (message) => {
        liveMessages.forEach((chat) => {
            const chatRoomId = chat?.chatRoomId;
            const chatRoomRef = firebaseClient.database().ref(chatRoomId);
            chatRoomRef.push(message);
        });
    };

    function findMSGKeyById(obj, id) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key]._id === id) {
                return key;
            }
        }
        return null;
    }


    const handleReply = (message) => {
        setSelectedChatDetails({
            chatRoomId: message.chatRoomId,
            isMentorMessage: message?.isMentorMessage ? true : false,
        });
        setReplyModalOpen(true);
        setMsgId(message._id);
    };

    const handleReplyMessage = () => {
        if (selectedChatDetails.isMentorMessage) {
            liveMessages.forEach((chat) => {
                pushReplyMessage(chat?.chatRoomId);
            });
            setMsgId("");
        } else {
            pushReplyMessage(selectedChatDetails.chatRoomId);
            setMsgId("");
        }
    };

    function pushReplyMessage(chatRoomId) {
        const chatRoomRef = firebaseClient.database().ref(chatRoomId);
        chatRoomRef.once("value", (snapshot) => {
            const messageData = snapshot.val();
            const key = findMSGKeyById(messageData, msgId);
            if (key) {
                const RoomRef = firebaseClient
                    .database()
                    .ref(chatRoomId)
                    .child(`${key}`);
                const reply = {
                    text: replyMessage,
                    isMentorMessage: true,
                    timestamp: moment().format(),
                    user: {
                        avatar: user?.avatar,
                        name: user?.name,
                        id: user?.staff?._id,
                    },
                };
                RoomRef.child("replies").push(reply);
            } else {
                console.error(`Message ID ${msgId} not found`);
            }
        });
    }

    const calculateOptionPercentages = (poll) => {
        const totalVotes =
            poll?.pollOptions?.reduce(
                (sum, option) => sum + (option.value || 0),
                0
            ) || 0;
        if (totalVotes === 0) {
            return poll?.pollOptions?.map(() => 0);
        }
        const pollVotes = poll?.pollOptions?.map(
            (option) => ((option.value || 0) / totalVotes) * 100
        );
        return pollVotes;
    };



    const handleLiveChange = (chatDetails) => {
        const classIds = [];
        liveMessages.forEach((element) => {
            classIds.push(element._id);
        });
        dispatch(getBatchSubjectStatusAction({ id: classIds, status: "Ended" }));
    };

    return (
        <div style={{ height: "100%" }}>
            <div style={{ display: "flex", background: "#F6F6F6", height: "100%", paddingBottom: "80px" }}>
                <div
                    style={{
                        width: "60%",
                        padding: "0 20px",
                        height: "100%",
                        overflowY: "scroll",
                    }}
                    className="CustomScrollBar"
                >
                    {/* {liveChatMessages?.length > 0 && (
                <Flex mb={2} align="center" justifyContent="space-between" style={{ padding: "15px 15px 5px 15px", borderBottom: "1px solid #e2e8f0" }}>
                <input
                    id={chat?._id}
                    type="checkbox" name="begin-class-checkbox"
                    onChange={(e) => { endSelectedClass(chat?._id) }}
                    style={{
                        width: "18px",
                        height: "18px",
                        display: "inline-block",
                        marginRight: "12px",
                        marginLeft: "5px",
                        cursor: "pointer "
                    }}
                />
                <Text>{chat?.batch}</Text>
                <Button onClick={(e) => handleEndClass(e)}>End Class</Button>
                </Flex>
            )} */}
                    <div
                        ref={chatContainerRef}
                        style={{
                            width: "100%",
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            overflowY: "auto",
                            padding: "15px 0 30px"
                        }}
                        id="commentBox"
                    >
                        {sortedChats?.length > 0 ? (
                            //  && chat?.chatRoomId !== undefined
                            sortedChats?.map((message, i) => (
                                <Box
                                    key={i}
                                    p="4"
                                    borderRadius="10px"
                                    borderWidth="1px"
                                    borderColor="border.disabled"
                                    color="fg.disabled"
                                    backgroundColor={"#E7E3E33B"}
                                    boxShadow={"0 1px 8px 0 #00000040"}
                                    margin={"0 30px"}
                                >
                                    {message?._id && (
                                        <div style={{ width: "100%", padding: "10px" }}>
                                            <Box display="flex" justifyContent="space-between">
                                                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                    <Avatar
                                                        style={{ height: "30px", width: "30px" }}
                                                        src={message?.user?.avatar}
                                                        name={message?.user?.name}
                                                    />
                                                    <Text fontWeight="bold">{message?.user?.name}</Text>
                                                </div>
                                                <Button style={{
                                                    padding: "3px 5px",
                                                }}>
                                                    {/* <TbDotsVertical/> */}
                                                </Button>
                                            </Box>
                                            <Box ms={10}>
                                                {message?.type === "poll" ? (
                                                    <>
                                                        <Text>{message.poll.pollQuestion}</Text>
                                                        <Box my={2}>
                                                            {message?.poll?.pollOptions?.map((option, i) => {
                                                                const percentages = calculateOptionPercentages(
                                                                    message?.poll
                                                                );
                                                                return (
                                                                    <div key={i}>
                                                                        <label className="d-flex align-items-center position-relative w-100">
                                                                            {option?.key}
                                                                            <Text className="position-absolute top-0 end-0">
                                                                                {option?.value}
                                                                            </Text>
                                                                        </label>
                                                                        <Progress
                                                                            mb={2}
                                                                            value={percentages[i]}
                                                                            colorScheme="green"
                                                                            size="xs"
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                        </Box>
                                                    </>
                                                ) : (
                                                    <p style={{ fontSize: "16px", color: "#1F1F39" }}>{message?.text}</p>
                                                )}
                                                {/* <Text>{message?.text}</Text> */}

                                                {!message?.isMentorMessage && (
                                                    <p style={{ fontSize: "16px", color: "#1f1f39b0", marginTop: "10px", }}>{message?.batch}</p>
                                                )}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Text fontSize="12px" color="#8B7C7C" fontWeight="500">
                                                        {moment(message?.timestamp).format(
                                                            "MMMM Do YYYY, h:mm:ss a"
                                                        )}
                                                    </Text>
                                                    <Button
                                                        style={{
                                                            background: "rgb(121, 125, 250)",
                                                            color: "white",
                                                            padding: "5px 20px",
                                                            fontWeight: 500,
                                                            borderRadius: 4,
                                                            height: "auto"
                                                        }}
                                                        onClick={() => handleReply(message)}
                                                    >
                                                        Reply
                                                    </Button>
                                                </div>

                                                {message?.replies !== undefined ? (
                                                    <Box mt={2} pl={4} borderLeft="1px solid #e2e8f0">
                                                        <Text fontSize="sm" color="gray.500">
                                                            Replies:
                                                        </Text>
                                                        {Object.entries(message.replies).map(
                                                            ([key, reply]) => (
                                                                <Flex key={key} align="center" my={1}>
                                                                    <Box mr={2}>
                                                                        <Avatar
                                                                            style={{ height: "30px", width: "30px" }}
                                                                            src={reply?.user?.avatar}
                                                                            name={reply?.user?.name}
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <Text fontWeight="bold">
                                                                            {reply?.user?.name}
                                                                        </Text>
                                                                        <Text>{reply?.text}</Text>
                                                                        <Text fontSize="10px" color="gray.500">
                                                                            {moment(reply?.timestamp).format(
                                                                                "MMMM Do YYYY, h:mm:ss a"
                                                                            )}
                                                                        </Text>
                                                                        {!message?.isMentorMessage && (
                                                                            <Text>{message?.batch}</Text>
                                                                        )}
                                                                    </Box>
                                                                </Flex>
                                                            )
                                                        )}
                                                    </Box>
                                                ) : (
                                                    <></>
                                                )}
                                            </Box>
                                        </div>
                                    )}
                                </Box>
                            ))
                        ) : (
                            <Text textAlign={"center"} color={"#b2b6bc"}>
                                No Data Available
                            </Text>
                        )}
                    </div>

                    {/* Reply Modal */}
                    <Modal isOpen={replyModalOpen} onClose={() => setReplyModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Reply to Message</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Input
                                    placeholder="Type your reply..."
                                    value={replyMessage}
                                    onChange={(e) => setReplyMessage(e.target.value)}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="ghost" onClick={() => setReplyModalOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    onClick={() => {
                                        handleReplyMessage();
                                        setReplyModalOpen(false);
                                        setReplyMessage("");
                                    }}
                                >
                                    Reply
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* Youtube Video Statistics */}
                    {/* {youtubeVideoData &&
                    <Grid
                        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
                        // direction={["column", "row"]}
                        // justifyItems="space-between"
                        margin="10px 10px 10px 10px"
                        gap={3}
                    >
                        <GridItem>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ViewIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                                <Text fontWeight="bold" fontSize="sm">Views:</Text>
                                <Text fontSize="sm" color="gray.700" ml={2}>{youtubeVideoData?.viewCount}</Text>
                            </div>
                        </GridItem>
                        <GridItem w={100}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ThumbsUpIcon fontSize="sm" mr={2} color="gray.600" />
                                <Text fontWeight="bold" fontSize="sm">Likes:</Text>
                                <Text fontSize="sm" color="gray.700" ml={2}>{youtubeVideoData?.likeCount}</Text>
                            </div>
                        </GridItem>
                        <GridItem w={100}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ChatIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                                <Text fontWeight="bold" fontSize="sm">Comments:</Text>
                                <Text fontSize="sm" color="gray.700" ml={2}>{youtubeVideoData?.commentCount}</Text>
                            </div>
                        </GridItem>
                        <GridItem w={100}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <AiOutlineHeart fontSize="sm" boxSize={5} mr={2} color="gray.600" style={{ width: "25px", height: "22px" }} />
                                <Text fontWeight="bold" fontSize="sm" marginLeft="5px">Favorite:</Text>
                                <Text fontSize="sm" color="gray.700" ml={2}>{youtubeVideoData?.favoriteCount}</Text>
                            </div>
                        </GridItem>
                    </Grid>
                } */}


                </div>
                <div
                    style={{
                        width: "40%",
                        padding: "20px 0 0",
                        height: "100%",
                        overflow: "auto",
                    }}
                    className="CustomScrollBar"
                >
                    <div style={{ border: "1px solid #797DFA", borderRadius: "4px", background: "#fff", width: "max-content", maxWidth: "100%", margin: "10px auto 40px" }}>
                        <Button style={{
                            borderRadius: "4px",
                            background: pollDoubtTab === "Poll" ? "#797DFA" : "#fff",
                            color: pollDoubtTab === "Poll" ? "#fff" : "#797DFA",
                            boxShadow: "none",
                            minWidth: "150px"
                        }}
                            onClick={() => setPollDoubtTab("Poll")}
                        >
                            Poll
                        </Button>
                        <Button style={{
                            borderRadius: "4px",
                            background: pollDoubtTab === "Doubts" ? "#797DFA" : "#fff",
                            color: pollDoubtTab === "Doubts" ? "#fff" : "#797DFA",
                            boxShadow: "none",
                            minWidth: "150px"
                        }}
                            onClick={() => setPollDoubtTab("Doubts")}
                        >
                            Doubts
                        </Button>
                    </div>
                    {
                        pollDoubtTab === "Poll" &&
                        <LiveClassPolls filtredPoll={filtredPoll} allLivePollRoomIds={allLivePollRoomIds} liveMessages={liveMessages} user={user} />
                    }
                    {
                        pollDoubtTab === "Doubts" &&
                        <LiveClassDoubts liveMessages={liveMessages} />
                    }
                </div>
            </div>
            <div style={{ background: "#fff", position: "fixed", bottom: 0, left: 0, width: "100%", padding: "15px 0", display: "flex" }}>

                <div style={{
                    padding: "5px 40px 5px 30px",
                    width: "60%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between",
                    borderRight: "2px solid #ABABAB",
                    alignItems: "center",
                }}>
                    <span style={{
                        width: 35,
                        height: 35,
                        borderRadius: 50,
                        background: "#797DFA",
                        padding: 10
                    }}>
                        <Image
                            src={"/images/chatLeftList.svg"}
                            width="100%"
                            height="100%"
                            alt=""
                        />
                    </span>
                    <Flex width="90%">
                        <Input
                            placeholder="Type your message..."
                            value={mentorMessage}
                            onChange={(e) => setMentorMessages(e.target.value)}
                        />
                        <Button ml={2} onClick={handleMentorMessages}
                            style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                background: "#797DFA",
                                padding: "7px 25px",
                                color: "#fff",
                                borderRadius: "5px",
                                height: "auto",
                                margin: "0 0 0 25px",
                                fontSize: "15px",
                                fontWeight: 500
                            }}
                        >
                            <span>Send</span> <Image width={15} src="/images/sendArrowIcon.svg" />
                        </Button>
                    </Flex>
                </div>
                <div style={{
                    padding: "0 45px 0 60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    width: "40%"
                }}>
                    <div style={{
                        padding: "0 15px 0 0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                        <Image
                            src={"/images/Teachers-icon.png"}
                            width="25px"
                            alt=""
                            style={{ marginRight: "10px" }}
                        />
                        <Heading fontSize="16px" color="#797DFA" width="auto">
                            Teachers: {allTeachers?.length || 0}
                        </Heading>
                    </div>
                    <div>
                        <button
                            onClick={() => handleLiveChange()}
                            className="btn"
                            style={{
                                height: "auto",
                                padding: "5px 10px",
                                backgroundColor: "#FF3E1F",
                                color: "white",
                                fontSize: "14px",
                                borderRadius: "5px",
                            }}
                        >
                            <Icon
                                as={AiFillPhone}
                                w={5}
                                h={5}
                                color="red"
                                marginRight="10px"
                                transform="rotate(226deg)"
                            />
                            End Class
                        </button>
                    </div>

                </div>

            </div>

        </div>
    );
};

const HistoryList = ({ chatDetails, searchDoubt, handleChangeSearch }) => {
    const { RangePicker } = DatePicker;
    const [roomData, setRoomData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedChatRoom, setSelectedChatRoom] = useState(null);
    const [sortedChatHistoryDetails, setSortedChatHistoryDetails] = useState([]);
    const [filteredChatHistoryDetails, setFilteredChatHistoryDetails] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        if (chatDetails?.Ended?.length) {
            const _chatDetails = [...chatDetails.Ended];
            _chatDetails.sort((b, a) => new Date(a.scheduleDateTime) - new Date(b.scheduleDateTime));
            setSortedChatHistoryDetails(_chatDetails);
            setFilteredChatHistoryDetails(_chatDetails);
        }
    }, [chatDetails?.Ended]);
    
    const handleDateRangeChange = (dates) => {
        if (!dates || dates.length !== 2) {
            setFilteredChatHistoryDetails(sortedChatHistoryDetails);
            return;
        }
        const startDate = moment(dates[0].$d).startOf('day');
        const endDate = moment(dates[1].$d).endOf('day');
    
        const filtered = sortedChatHistoryDetails.filter(item =>
            moment(item.createdAt).isBetween(startDate, endDate, null, '[]')
        );
        setFilteredChatHistoryDetails(filtered);
    };
    

    const handleChatSearch = (e) => {
        let value = e.target.value || "";
        setSearchQuery(value);
        let filtered = sortedChatHistoryDetails.filter(item =>
            item.lectureName?.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredChatHistoryDetails(filtered);
    };
    

    const chatRoomChatDetails = async (chatRoomId) => {
        if (chatRoomId === undefined) {
            return;
        } else {
            try {
                setIsLoading(true);
                const snapshot = await firebaseClient
                    .database()
                    .ref(chatRoomId)
                    .once("value");
                const data = snapshot.val();
                const dataArray = Object.values(data);
                setRoomData(dataArray);
                setIsLoading(false);
                setSelectedChatRoom(chatRoomId);
            } catch (error) {
                console.error("Error fetching chat details:", error);
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="chatbox-block">
            <div className="row">
                {/* Sidebar for lectures */}
                <div className="col-sm-5 lecture-left-block">
                    <div className="seactch-dater-filter">
                        <div className="search-section">
                        <InputGroup>
                            <InputRightElement pointerEvents="none">
                                <Search2Icon color="#4559d2" />
                            </InputRightElement>
                            <Input
                                value={searchQuery}
                                onChange={handleChatSearch}
                                placeholder="Search Lecture"
                            />
                        </InputGroup>
                        </div>
                        <div className="date-section">
                            <RangePicker  onChange={handleDateRangeChange} id="kt_daterangepicker_4"/>
                        </div>
                    </div>
                    <hr style={{ margin: "5px 0" }}></hr>
                    <div>
                        {filteredChatHistoryDetails?.length > 0 &&
                            filteredChatHistoryDetails.map((endedItem, i) => (
                                <div
                                    className="lecture-box"
                                    key={endedItem._id || i}
                                    onClick={() => chatRoomChatDetails(endedItem?.chatRoomId)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="lecture-content">
                                        <b>{endedItem.lectureName}</b>
                                        <p>{endedItem.batch}</p>
                                    </div>
                                    <div className="lecture-date-time">
                                        <span>{moment(endedItem.createdAt).format("DD/MM/YYYY")} </span>
                                        <span>{moment(endedItem.createdAt).format("HH:mm")}</span>
                                    </div>
                                    {endedItem?.youtubeVideoId && (
                                        <HistoryListVideoStats youtubeVideoId={endedItem?.youtubeVideoId} />
                                    )}
                                    <hr style={{ margin: "5px 0" }} />
                                </div>
                            ))
                        }
                    </div>
                </div>

                {/* Main chat area */}
                <div className="col-sm-7 lecture-chat-list">
                    {selectedChatRoom ? (
                        <>
                            {isLoading ? (
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="loader"></span>
                                </div>
                            ) : roomData?.length > 0 ? (
                                roomData.map((message, i) => (
                                    <Flex key={i} align="center" my={2}>
                                        <Box mr={2}>
                                            <Avatar
                                                style={{ height: "30px", width: "30px" }}
                                                src={message?.user?.avatar}
                                                name={message?.user?.name}
                                            />
                                        </Box>
                                        <Box>
                                            <Text fontWeight="bold">{message?.user?.name}</Text>
                                            <Text>{message?.text}</Text>
                                            <Text fontSize="sm" color="gray.500">
                                                {moment(message?._id).format("MMMM Do YYYY, h:mm:ss a")}
                                            </Text>
                                            {/* <Button size="xs" onClick={() => handleReply(message._id)}>Reply</Button> */}

                                            {message?.replies !== undefined ? (
                                                <Box mt={2} pl={4} borderLeft="1px solid #e2e8f0">
                                                    <Text fontSize="sm" color="gray.500">
                                                        Replies:
                                                    </Text>
                                                    {Object.entries(message.replies).map(
                                                        ([key, reply]) => (
                                                            <Flex key={key} align="center" my={1}>
                                                                <Box mr={2}>
                                                                    <Avatar
                                                                        style={{ height: "30px", width: "30px" }}
                                                                        src={reply?.user?.avatar}
                                                                        name={reply?.user?.name}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Text fontWeight="bold">
                                                                        {reply?.user?.name}
                                                                    </Text>
                                                                    <Text>{reply?.text}</Text>
                                                                    <Text fontSize="sm" color="gray.500">
                                                                        {moment(reply?._id).format(
                                                                            "MMMM Do YYYY, h:mm:ss a"
                                                                        )}
                                                                    </Text>
                                                                </Box>
                                                            </Flex>
                                                        )
                                                    )}
                                                </Box>
                                            ) : (
                                                <></>
                                            )}
                                        </Box>
                                    </Flex>
                                ))
                            ) : (
                                <Text
                                    style={{
                                        textAlign: "center",
                                        color: "grey",
                                        marginTop: "5px",
                                    }}
                                >
                                    No Chats Available
                                </Text>
                            )}
                        </>
                    ) : (
                        <Text
                            style={{ textAlign: "center", color: "grey", marginTop: "5px" }}
                        >
                            Select a lecture to see chats
                        </Text>
                    )}
                </div>
            </div>
        </div>
    );
};
const HistoryListVideoStats = ({ youtubeVideoId }) => {
    // const dispatch = useDispatch();

    const [youtubeVideoData, setYoutubeVideoData] = useState(null);
    // const {states, user, youtubeVideoStats} = useSelector((s) => ({
    //     states: s,
    //     user: s.user.user,
    //     youtubeVideoStats: s.user.youtubeVideoStats?.statistics,
    // }));

    // useEffect(() => {
    //     if (youtubeVideoId) {
    //         dispatch(getYouTubeVideoStatsAction({ video_value: youtubeVideoId }));
    //     }
    // }, [youtubeVideoId]);

    useEffect(() => {
        if (youtubeVideoId) {
            const fetchYoutubeVideoStats = async () => {
                try {
                    const response = await apis.getYouTubeVideoStatsApi({
                        video_value: youtubeVideoId,
                    });
                    const res = response.data?.response?.statistics;
                    setYoutubeVideoData(res);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchYoutubeVideoStats();
        }
    }, [youtubeVideoId]);
    return (
        <>
            {youtubeVideoData && (
                <Grid
                    templateColumns={{
                        base: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)",
                        lg: "repeat(2, 1fr)",
                    }}
                    margin="10px 10px 10px 10px"
                    gap={3}
                >
                    <GridItem w={100}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ViewIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                            <Text fontWeight="bold" fontSize="sm">
                                Views:
                            </Text>
                            <Text fontSize="sm" color="gray.700" ml={2}>
                                {youtubeVideoData?.viewCount}
                            </Text>
                        </div>
                    </GridItem>
                    <GridItem>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ThumbsUpIcon fontSize="sm" mr={2} color="gray.600" />
                            <Text fontWeight="bold" fontSize="sm">
                                Likes:
                            </Text>
                            <Text fontSize="sm" color="gray.700" ml={2}>
                                {youtubeVideoData?.likeCount}
                            </Text>
                        </div>
                    </GridItem>
                    <GridItem>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ChatIcon fontSize="sm" boxSize={5} mr={2} color="gray.600" />
                            <Text fontWeight="bold" fontSize="sm">
                                Comments:
                            </Text>
                            <Text fontSize="sm" color="gray.700" ml={2}>
                                {youtubeVideoData?.commentCount}
                            </Text>
                        </div>
                    </GridItem>
                    <GridItem textAlign="right">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <AiOutlineHeart
                                fontSize="sm"
                                boxSize={5}
                                mr={2}
                                color="gray.600"
                                style={{ width: "25px", height: "22px" }}
                            />
                            <Text fontWeight="bold" fontSize="sm" marginLeft="5px">
                                Favorite:
                            </Text>
                            <Text fontSize="sm" color="gray.700" ml={2}>
                                {youtubeVideoData?.favoriteCount}
                            </Text>
                        </div>
                    </GridItem>
                </Grid>
            )}
        </>
    );
};
const UpcomingList = ({ chatDetails, handleTabChange }) => {
    const toast = useToast();
    const dispatch = useDispatch();

    const [msgId, setMsgId] = useState("");
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [pollModalOpen, setPollModalOpen] = useState(false);
    const [currentPollRoomId, setCurrentPollRoomId] = useState(null);
    const [pollDetails, setPollDetails] = useState(null);

    const { user, getBatchSubjectLectureStatus } = useSelector((s) => ({
        user: s.user.user,
        getBatchSubjectLectureStatus: s.doubts.getBatchSubjectLectureStatus,
    }));

    useEffect(() => {
        if (!pollModalOpen) {
            setPollDetails(null)
            setCurrentPollRoomId(null)
        }
    }, [pollModalOpen])

    useEffect(() => {
        if (getBatchSubjectLectureStatus === STATUS.SUCCESS) {
            setSelectedClasses([]);
        }
    }, [getBatchSubjectLectureStatus]);

    const handleBeginClass = (classId) => {
        let Classes = selectedClasses;
        if (Classes.includes(classId)) {
            setSelectedClasses(Classes.filter((id) => id !== classId));
        }
        dispatch(getBatchSubjectStatusAction({ id: [classId], status: "Live" }));
        handleTabChange({ target: { value: "0" } });
    };

    const _beginSelectedClasses = (classes) => {

        if (classes.length > 0) {
            dispatch(getBatchSubjectStatusAction({ id: classes, status: "Live" }));
        }
    };

    const _selectClass = (classId, e) => {
        let Classes = selectedClasses;
        if (Classes.includes(classId)) {
            setSelectedClasses(Classes.filter((id) => id !== classId));
        } else {
            setSelectedClasses((classes) => [...classes, classId]);
        }
    };

    const handleOpenPollModal = (pollRoomId) => {
        setCurrentPollRoomId(pollRoomId);
        setPollModalOpen(true);
    };

    const getPollChatDetails = (pollRoomId) => {
        const chatRoomRef = firebaseClient.database().ref(pollRoomId);
        chatRoomRef
            .once("value")
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    if (!Object?.keys(data).every((key) => key === "created_at")) {
                        const pollData = Object?.values(data)[0];
                        setMsgId(pollData._id);
                        setPollDetails(pollData.pollDetails);
                        // handleOpenPollModal(pollRoomId);
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const handlePollRoomMessage = (message) => {
        const chatRoomId = currentPollRoomId;
        const chatRoomRef = firebaseClient.database().ref(chatRoomId);
        chatRoomRef.push(message);
    };

    const updatePollDetails = (pollRoomId, messageId, updatedPollDetails) => {
        const chatRoomRef = firebaseClient.database().ref(pollRoomId);

        // Find the specific message by its `_id` and update only `pollDetails`
        chatRoomRef
            .once("value")
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const messages = snapshot.val();
                    // Find the key corresponding to the message with the given `_id`
                    const messageKey = Object.keys(messages).find(
                        (key) => messages[key]._id === messageId
                    );

                    if (messageKey) {
                        chatRoomRef
                            .child(`${messageKey}/pollDetails`)
                            .set(updatedPollDetails)
                            .then(() => {
                                dispatch(getChatDetailsAction({ teacherId: user?._id }));
                            })
                            .catch((error) => {
                                console.error("Error updating poll details:", error);
                            });
                    } else {
                        console.error("Message not found.");
                    }
                } else {
                    console.error("No messages in the room.");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const handlePollSubmit = (pollData) => {
        if (pollData?.length > 0) {
            if (pollDetails?.length > 0 && msgId) {
                updatePollDetails(currentPollRoomId, msgId, pollData);
            } else {
                const message = {
                    timestamp: moment().format(),
                    pollDetails: pollData?.map((poll, index) => ({
                        ...poll,
                        _id: moment().unix() + Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000,
                    })),
                    staffDetails: {
                        avatar: user?.avatar,
                        name: user?.name || "Mentor",
                        id: user?.staff?._id,
                    },
                };
                handlePollRoomMessage(message);
            }
            setPollModalOpen(false);
        }
    };

    return (
        <div>
            {selectedClasses?.length > 0 && (
                <div className="d-flex justify-content-end pb-4 ">
                    <Button
                        onClick={() => {
                            _beginSelectedClasses(selectedClasses);
                        }}
                    >
                        Begin Selected Classes
                    </Button>
                </div>
            )}
            {chatDetails?.Upcoming.length > 0 ?
                chatDetails?.Upcoming.map((classDetail, i) => (
                    <Box
                        key={i}
                        style={{
                            border: "1px solid #8484843d",
                            padding: "5px",
                            borderRadius: "5px",
                            marginBottom: "5px",
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <input
                                id={`beginClass-${i + 1}`}
                                type="checkbox"
                                name="begin-class-checkbox"
                                onChange={(e) => {
                                    _selectClass(classDetail?._id, e);
                                }}
                                checked={selectedClasses.includes(classDetail?._id)}
                                style={{
                                    width: "18px",
                                    height: "18px",
                                    display: "inline-block",
                                    marginRight: "12px",
                                    marginLeft: "5px",
                                    cursor: "pointer ",
                                }}
                            />
                            <div
                                className="d-flex align-items-center justify-content-between "
                                style={{ width: "calc(100% - 35px)" }}
                            >
                                <div className="col-3">
                                    <div className="fs-6 fw-bold ">
                                        {classDetail?.lectureName}
                                    </div>
                                    <div>{classDetail?.batch}</div>
                                </div>
                                <div className="col-3 text-center">
                                    <div style={{ padding: "10px" }}>
                                        {moment(classDetail?.scheduleDateTime).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                        )}
                                    </div>
                                </div>
                                <Flex>
                                    <div className="me-1  text-end">
                                        <Button
                                            colorScheme={"primary"}
                                            fontSize="14px"
                                            fontWeight="600"
                                            onClick={() =>
                                                handleOpenPollModal(classDetail?.pollRoomId)
                                            }
                                        >
                                            Polls
                                        </Button>
                                    </div>
                                    {/* <div className="me-1  text-end">
                    <Button colorScheme={"primary"}
                    fontSize="14px" fontWeight="600"
                      onClick={() => getPollChatDetails(classDetail?.pollRoomId)}>Poll Details</Button>
                  </div> */}
                                    <div className="me-1  text-end">
                                        <Button
                                            colorScheme={"primary"}
                                            fontSize="14px"
                                            fontWeight="600"
                                            onClick={() => handleBeginClass(classDetail?._id)}
                                        >
                                            Begin Class
                                        </Button>
                                    </div>
                                </Flex>
                            </div>
                        </div>
                    </Box>
                ))
                :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "80vh", fontSize: "20px", color: "#00000070" }}>
                    No data found
                </div>

            }

            {pollModalOpen && (
                <PollCreateForm
                    pollModalOpen={pollModalOpen}
                    setPollModalOpen={setPollModalOpen}
                    handlePollSubmit={handlePollSubmit}
                    pollDetails={pollDetails}
                    getPollChatDetails={getPollChatDetails}
                    pollRoomId={currentPollRoomId}
                />
            )}
        </div>
    );
};

const CustomTab = ({ title }) => {
    return (
        <Tab
            marginX={[1, 1, 1, 2]}
            paddingX={3}
            p={2}
            background="#EFF3F6"
            color={"black"}
            _selected={{ background: "brand.blue", color: "white", borderRadius: 4 }}
        >
            {title}
        </Tab>
    );
};

const MobileTeacherList = ({ resetSearch }) => {
    const params = useParams();
    const { teachers } = useSelector((state) => ({
        teachers: state.doubts.allTeachers,
    }));

    const [currentTeacher, setTeacher] = useState();
    const [openIndex, changeOpenIndex] = useState([0]);

    useEffect(() => {
        changeOpenIndex([]);
    }, [params.staffId]);

    useEffect(() => {
        if (params.staffId && teachers) {
            let staff = _.find(teachers, (t) => t.user?._id === params.staffId);
            setTeacher(staff);
        }
    }, [params, teachers]);

    const handleOpen = () => {
        changeOpenIndex((d) => (d.length ? [] : [0]));
    };

    return (
        <>
            <Accordion index={openIndex} allowMultiple {...mobileView}>
                <AccordionItem>
                    <AccordionButton onClick={handleOpen}>
                        <Box flex="1" textAlign="left">
                            <HStack justify={"space-between"}>
                                <Text color={"brand.blue"}>{currentTeacher?.user.name}</Text>
                                <Text>Teachers ({teachers?.length}) &nbsp;</Text>
                            </HStack>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <MemorizeTeachersList resetSearch={resetSearch} hideHeading />
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Flex
                justifyContent="flex-end"
                display={["flex", "flex", "flex", "none"]}
                my={2}
            ></Flex>
        </>
    );
};

const TeachersList = ({ hideHeading, resetSearch }) => {
    const history = useHistory();
    const params = useParams();

    const [allTeachers, changeTeachers] = useState([]);
    const [sortOn, setSortOn] = useState("doubt");
    const [sortType, changeSortType] = useState("asc");
    const { teachers } = useSelector((state) => ({
        teachers: state.doubts.allTeachers,
    }));

    useEffect(() => {
        if (teachers?.length) changeTeachers(teachers);
    }, [teachers]);

    useEffect(() => {
        handleSort();
    }, [sortOn, sortType]);

    const handleTeacher = (teach) => {
        resetSearch();
        history.push(`/doubts/1/${teach.user?._id}`);
    };

    const handleChange = (e) => {
        let searchText = e.target.value;
        let list = _.filter(teachers, (t) =>
            _.includes(_.lowerCase(t?.user?.name), _.lowerCase(searchText))
        );
        changeTeachers(list);
    };
    const handleSort = () => {
        if (sortOn === "doubt")
            changeTeachers((d) =>
                _.orderBy(
                    d,
                    (doubt) => (!doubt.doubtCount ? 0 : doubt.doubtCount),
                    sortType
                )
            );
        else
            changeTeachers((d) =>
                _.orderBy(
                    d,
                    (doubt) =>
                        !doubt.doubtCountResolved || !doubt.doubtCount
                            ? 0
                            : doubt.doubtCountResolved / doubt.doubtCount,
                    sortType
                )
            );
    };
    return (
        <List h="100%">
            {hideHeading ? null : (
                <ListItem background={"gray.100"} borderRadius={4} py={2} px={3}>
                    <HStack>
                        <Text>Teachers</Text>
                        <Text>({allTeachers?.length || 0})</Text>
                    </HStack>
                </ListItem>
            )}
            <ListItem my={3}>
                <HStack>
                    <Box flex={1}>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<Search2Icon color="gray.300" />}
                            />
                            <Input onChange={handleChange} placeholder="Search Teacher" />
                        </InputGroup>
                    </Box>
                </HStack>
            </ListItem>
            <ListItem my={3}>
                <Flex>
                    <Button
                        onClick={() =>
                            changeSortType((d) => (d === "asc" ? "desc" : "asc"))
                        }
                        size="sm"
                        variant="outline"
                        colorScheme="blue"
                        width={"100%"}
                        mr={3}
                        rightIcon={sortType === "asc" ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    >
                        <Box>Sort </Box>
                    </Button>
                    <Menu>
                        <MenuButton
                            as={Button}
                            size="sm"
                            px={4}
                            colorScheme="blue"
                            rightIcon={<ChevronDownIcon />}
                        >
                            {sortOn === "doubt" ? "Count" : "Top"}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => setSortOn("doubt")}>
                                Doubt count
                            </MenuItem>
                            <MenuItem onClick={() => setSortOn("top comment")}>Top</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </ListItem>
            <ListItem my={3}>
                <HStack>
                    <Box flex={1}></Box>
                </HStack>
            </ListItem>
            <List
                maxH="100vh"
                overflowY="scroll"
                overflowX="hidden"
                pos="sticky"
                top="0px"
                css={{
                    "&::-webkit-scrollbar": { width: "6px" },
                    "&::-webkit-scrollbar-thumb": {
                        background: "#cdcdcd",
                        borderRadius: "5px",
                    },
                }}
            >
                {allTeachers?.length
                    ? allTeachers.map((teach) => {
                        const active = teach.user?._id === params.staffId;
                        return (
                            teach.user && (
                                <ListItem
                                    borderRadius={active ? 5 : 2}
                                    my={1}
                                    cursor={"pointer"}
                                    _hover={{ background: "blue.200", color: "white" }}
                                    py={1}
                                    px={2}
                                    key={teach._id}
                                    transition="all .3s"
                                    onClick={() => handleTeacher(teach)}
                                    background={active && "brand.blue"}
                                    color={active ? "white" : "brand.secondary"}
                                >
                                    <HStack justify={"space-between"} alignItems="flex-start">
                                        <Box pt={1}>
                                            <Text>{teach.user?.name}</Text>
                                            <Text fontSize="xs">{teach.staffDesc}</Text>
                                        </Box>
                                        <HStack display={"flex"}>
                                            <Box fontSize={11}>
                                                <Box>Doubts</Box>
                                                <Flex justifyContent="center">
                                                    <Box>{teach?.doubtCountResolved || 0}</Box>
                                                    <Box px={1}>/</Box>
                                                    <Box textAlign="left">{teach.doubtCount || 0}</Box>
                                                </Flex>
                                            </Box>
                                            {
                                                <ChevronRightIcon
                                                    visibility={active ? "" : "hidden"}
                                                    fontSize={24}
                                                    color={"white"}
                                                />
                                            }
                                        </HStack>
                                    </HStack>
                                </ListItem>
                            )
                        );
                    })
                    : null}
            </List>
        </List>
    );
};

const MemorizeTeachersList = React.memo(TeachersList);

const DoubtItem = ({ onPress, d, i }) => {
    const [isCopied, setIsCopied] = useState(false);
    const doubtStatus =
        d.status === "Closed"
            ? { label: "Closed", color: "red" }
            : { label: d.status, color: "green" };

    const publicStatus = { label: "Public", color: "green" };

    const _open = () => {
        onPress(d);
    };
    const copyText = (text) => {
        if (!navigator || isCopied) return;
        navigator.clipboard.writeText(text);
        setIsCopied(true);
        window.setTimeout(() => {
            setIsCopied(false);
        }, 1300);
    };
    return (
        <Flex
            onClick={_open}
            p={2}
            px={4}
            _hover={{ bg: "gray.100", boxShadow: "sm" }}
            cursor="pointer"
            border="1px solid #dddddd"
            borderRadius={"md"}
            m={1}
        >
            <Box mr={5}>
                <Text fontSize="" color="gray.700">
                    {i}.
                </Text>
            </Box>
            <Box>
                <Flex>
                    <Text fontSize="sm" color="gray.600">
                        {d?.userId?.name}
                    </Text>
                    <Text color="gray.500" fontSize="xs" ml={10}>
                        <i>{moment(d?.createdAt).format("DD MMM YYYY hh:mm a")}</i>
                    </Text>
                </Flex>
                <Box pb={2} pt={1}>
                    <Text fontSize="sm">{d?.curatedQuery}</Text>
                </Box>
                <HStack mt={3}>
                    <Tag size="sm" colorScheme={doubtStatus.color}>
                        {doubtStatus.label}
                    </Tag>
                    {d?.public ? (
                        <Tag colorScheme={publicStatus.color}>{publicStatus.label}</Tag>
                    ) : null}
                    <Box onClick={(e) => e.stopPropagation()}>
                        <Popover>
                            <PopoverTrigger>
                                <Tag _hover={{ boxShadow: "lg" }} size="sm" colorScheme="blue">
                                    <PhoneIcon color="blue.400" />
                                </Tag>
                            </PopoverTrigger>
                            <PopoverContent boxShadow="none!important">
                                <PopoverArrow />

                                <Flex justifyContent="space-between">
                                    <PopoverBody textAlign="center" flexGrow="1">
                                        {d?.userId?.contact}
                                    </PopoverBody>
                                    <Button
                                        variant="ghost"
                                        mr={2}
                                        onClick={() => copyText(d?.userId?.contact)}
                                    >
                                        {isCopied ? (
                                            <CheckCircleIcon color="green.400" />
                                        ) : (
                                            <CopyIcon />
                                        )}
                                    </Button>
                                </Flex>
                            </PopoverContent>
                        </Popover>
                    </Box>
                </HStack>
            </Box>
        </Flex>
    );
};

const DoubtDetails = ({
    isOpen,
    onClose,
    currentDoubt,
    test,
    courseContent,
    checkRole,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(resetReadAction());
    }, [dispatch]);

    useEffect(() => {
        let itemId = test?.itemId || courseContent?.itemId;
        if (itemId && !checkRole) dispatch(readCommentAction({ itemId }));
    }, [dispatch, courseContent, test, checkRole]);

    return (
        <Drawer isOpen={isOpen} size={["xl"]} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent pt={10}>
                <DrawerCloseButton mt={10} />
                <DrawerHeader>
                    {test ? (
                        <Box alignItems="center">
                            <Box color="gray.800">{test.itemName}</Box>
                        </Box>
                    ) : courseContent ? (
                        <Box alignItems="center">
                            <Box color="gray.800">{courseContent.itemName}</Box>
                        </Box>
                    ) : (
                        <Box alignItems="center">
                            <Box color="gray.800">{currentDoubt.user?.name}</Box>
                            <Box fontSize={13} color="gray.500">
                                <i>
                                    {moment(currentDoubt.createdAt).format(
                                        "DD MMM YYYY, hh mm a"
                                    )}
                                </i>
                            </Box>
                        </Box>
                    )}
                </DrawerHeader>

                <DrawerBody style={{ maxHeight: "100vh" }}>
                    {test ? (
                        <Box>
                            <DiscussionComments
                                onBottomAtSmall={true}
                                commentsLabel="Comments"
                                showAttachment={false}
                                placeholder="Add a comment"
                                inputStyle="flushed"
                                itemId={test.itemId}
                                includeDoubt={2}
                            />
                        </Box>
                    ) : courseContent?.content ? (
                        <Box>
                            <DiscussionComments
                                onBottomAtSmall={true}
                                commentsLabel="Comments"
                                showAttachment={false}
                                placeholder="Add a comment"
                                inputStyle="flushed"
                                itemId={courseContent.itemId}
                                itemModel={courseContent.itemModel}
                                contentId={courseContent.content}
                                courseId={courseContent.course}
                                includeDoubt={2}
                            />
                        </Box>
                    ) : (
                        <DoubtComponent currentDoubt={currentDoubt} />
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export const DoubtComponent = ({ currentDoubt }) => {
    const dispatch = useDispatch();

    const { closeDoubtStatus, publicDoubtStatus, user } = useSelector(
        (state) => ({
            closeDoubtStatus: state.doubts.closeDoubtStatus,
            publicDoubtStatus: state.doubts.publicDoubtStatus,
            user: state.user.user,
        })
    );

    const checkRole = useMemo(() => {
        return (
            user?.staff?.staffRole === "ADMIN" ||
            user?.staff?.staffRole === "HEAD_TEACHER" ||
            user?.staff?.staffRole === "TEACHER"
        );
    }, [user?.staff?.staffRole]);

    const handleClose = () => {
        let data = {
            status: "Closed",
            doubtId: currentDoubt._id,
            closedDate: moment().format(),
        };
        dispatch(closeDoubtAction(data));
    };

    const handlePublic = () => {
        let data = {
            doubtId: currentDoubt._id,
            public: currentDoubt.public ? false : true,
        };
        dispatch(publicDoubtAction(data));
    };

    return (
        <>
            <HStack>
                <Button
                    disabled={currentDoubt.status === "Closed"}
                    size="sm"
                    isLoading={closeDoubtStatus === STATUS.FETCHING}
                    onClick={handleClose}
                    variant="outline"
                    color={"brand.red"}
                >
                    {currentDoubt.status === "Closed" ? "Closed" : "Close Doubt"}
                </Button>
                <Button
                    size="sm"
                    isLoading={publicDoubtStatus === STATUS.FETCHING}
                    variant="outline"
                    onClick={handlePublic}
                    color={currentDoubt.public ? "brand.red" : "brand.green"}
                    disabled={currentDoubt.status === "Open"}
                >
                    {currentDoubt.public ? "Make it Private" : "Make it Public"}
                </Button>
            </HStack>
            <br />
            <Box
                fontSize="md"
                color={"brand.secondary"}
                fontFamily='"Lato", sans-serif'
                as="pre"
                py={3}
                sx={{
                    "white-space": "-moz-pre-wrap",
                    "white-space": "-o-pre-wrap",
                    "word-wrap": "break-word",
                    "white-space": "pre-wrap",
                }}
            >
                {currentDoubt.doubt}
            </Box>
            <br />
            <br />
            <Box>
                <DiscussionComments
                    onBottomAtSmall={true}
                    editCommentUserId={checkRole && user._id}
                    commentsLabel="Comments"
                    showAttachment={true}
                    placeholder="Add a comment"
                    inputStyle="flushed"
                    itemId={currentDoubt._id}
                />
            </Box>
        </>
    );
};

const Timer = ({ startTime, duration, poll, handleCompletedPoll }) => {
    const [timeLeft, setTimeLeft] = useState(duration * 60 * 1000);

    const formatTime = (ms) => {
        let seconds = Math.max(0, Math.floor(ms / 1000));
        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        let hours = Math.floor(minutes / 60);
        minutes = minutes % 60;

        if (hours > 0) {
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        } else {
            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const startTimestamp = new Date(startTime).getTime();
            const durationMs = duration * 60 * 1000;
            const initialTimeLeft = durationMs - (Date.now() - startTimestamp);

            if (initialTimeLeft <= 1000) {
                handleCompletedPoll(poll);
                clearInterval(interval);
            }
            else {
                setTimeLeft(initialTimeLeft)
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [duration]);

    return <span>{formatTime(timeLeft)}</span>;
};

const LiveClassPolls = ({ filtredPoll, allLivePollRoomIds, liveMessages, user }) => {
    const dispatch = useDispatch();
    const [pollModalOpen, setPollModalOpen] = useState(false);
    const [currentPollDetials, setCurrentPollDetials] = useState(null);
    const [addToAllRooms, setAddToAllRooms] = useState(false);
    const [activeLeaderBoards, setActiveLeaderBoard] = useState({});


    const handleStartPoll = (poll) => {
        for (const pollRoomId of allLivePollRoomIds) {
            changePollStatus(pollRoomId, poll, "Start");
        }
    };
    const handleEndPoll = (poll) => {
        for (const pollRoomId of allLivePollRoomIds) {
            changePollStatus(pollRoomId, poll, "End");
        }
    };
    const handleCompletedPoll = (poll) => {
        for (const pollRoomId of allLivePollRoomIds) {
            changePollStatus(pollRoomId, poll, "Complete");
        }
    };
    const _editPoll = (poll) => {
        setCurrentPollDetials([poll]);
        setPollModalOpen(true)
        setAddToAllRooms(true);
    }
    const _checkLivePoll = () => {
        let livePollFlag = false;
        for (let i = 0; i < filtredPoll.length; i++) {
            const poll = filtredPoll[i];
            if (poll?.pollStatus === "Start" || poll?.pollStatus === "Complete") {
                livePollFlag = true;
                break;
            }
        }
        return livePollFlag;
    }
    const addNewPoll = () => {
        setCurrentPollDetials(null)
        setAddToAllRooms(true);
        setPollModalOpen(true);
    };
    function findKeyById(obj, id) {
        let finalKey = null;
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key]?.pollDetails?.length > 0) {
                obj[key].pollDetails.forEach((pd) => {
                    if (pd?._id === id) {
                        finalKey = key;
                        return;
                    }
                });
            }
            return finalKey;
        }
        return finalKey;
    }
    const changePollStatus = (pollRoomId, poll, status) => {
        const chatRoomRef = firebaseClient.database().ref(pollRoomId);

        if (chatRoomRef) {
            chatRoomRef
                .once("value")
                .then((snapshot) => {
                    const data = snapshot.val();
                    const key = findKeyById(data, poll?._id);
                    if (key !== "created_at") {
                        let keyData = data?.[key]?.pollDetails;
                        const pollData = keyData?.map((pd) => {
                            if (pd?._id === poll?._id) {
                                if (status === "Complete") {
                                    return { ...pd, pollStatus: "Complete" };
                                }
                                else if (status === "End") {
                                    return { ...pd, pollStatus: "End", endTime: moment().toISOString() };
                                }
                                else if (status === "Start") {
                                    return { ...pd, pollStatus: "Start", startTime: moment().toISOString() };
                                }
                                else if (status === "edit") {
                                    return poll;
                                }

                            } else return pd;
                        });

                        if (keyData?.length > 0) {

                            chatRoomRef
                                .child(key)
                                .update({ ...poll, pollDetails: pollData })
                                .then(() => {
                                    dispatch(getChatDetailsAction({ teacherId: user?._id }));
                                })
                                .catch((error) => {
                                    console.error("Error updating poll details:", error);
                                });

                        }
                    } else {
                        console.error("Poll with given ID not found.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    };
    const handleAddtoAllRooms = (pollData) => {
        if (pollData?.length > 0) {
            for (const pollRoomId of allLivePollRoomIds) {
                handleAddPolltoAll(pollRoomId, pollData);
            }
            setPollModalOpen(false);
        }
    };
    const handleAddPolltoAll = (chatRoomId, message) => {
        const chatRoomRef = firebaseClient.database().ref(chatRoomId);
        chatRoomRef.once("value", (snapshot) => {
            let data = snapshot.val();
            const key = Object.keys(data)[0];
            if (key !== "created_at") {
                let prevPolls = data?.[key]?.pollDetails || []
                prevPolls = prevPolls.filter((poll) => poll?._id !== message?.[0]?._id);
                let updatedPollDetails = [...prevPolls, ...message];
                chatRoomRef
                    .child(`${key}/pollDetails`)
                    .set(updatedPollDetails)
                    .then(() => {
                        dispatch(getChatDetailsAction({ teacherId: user?._id }));
                    })
                    .catch((error) => {
                        console.error("Error updating poll details:", error);
                    });
            } else {
                const Poll = {
                    timestamp: moment().format(),
                    pollDetails: message,
                    staffDetails: {
                        avatar: user?.avatar,
                        name: user?.name || "Mentor",
                        id: user?.staff?._id,
                    },
                };
                chatRoomRef
                    .push(Poll)
                    .then(() => {
                        dispatch(getChatDetailsAction({ teacherId: user?._id }));
                    })
                    .catch((error) => {
                        console.error("Error updating poll details:", error);
                    });
            }
        });
    };
    const handleEditPollSubmit = (pollData) => {
        if (pollData?.length > 0) {
            for (const pollRoomId of allLivePollRoomIds) {
                changePollStatus(pollRoomId, pollData[0], "edit");
            }
        }
        setPollModalOpen(false);
        setCurrentPollDetials(null);
        setAddToAllRooms(false);
    }
    const _calOptionPercentage = (allPolls, currentPoll) => {

        if (allPolls && currentPoll) {
            let percentage = (currentPoll / allPolls) * 100;
            return Math.round(percentage);
        } else {
            return 0;
        }
    }
    const _findVoterTimestamp = (pollStartTime, voterAttamptime) => {
        if (pollStartTime && voterAttamptime) {
            const pollTime = moment(pollStartTime);
            const attemptTime = moment(voterAttamptime);

            const duration = moment.duration(attemptTime.diff(pollTime));
            const hours = String(duration.minutes()).padStart(2, '0');
            const minutes = String(duration.seconds()).padStart(2, '0');
            const timeDifference = `${hours}:${minutes}`;

            return timeDifference;

        } else {
            return "N/A"
        }
    }



    return (
        <div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    padding: "0 20px"
                }}
            >
                {filtredPoll &&
                    filtredPoll.map((poll, i) => {
                        let ascVoters = poll?.voterDetails || [];
                        if (ascVoters?.length > 0) {
                            ascVoters = ascVoters.sort((a, b) => {
                                if (a?.voterDetails?.length > 0 && b?.voterDetails?.length > 0) {
                                    const dateA = new Date(a.voterDetails[0]?.userBean?.pollAnswerDateTime);
                                    const dateB = new Date(b.voterDetails[0]?.userBean?.pollAnswerDateTime);
                                    return dateA - dateB;
                                }
                                return 0;
                            });
                        }
                        let notAnswered = ascVoters?.filter((asc) => asc?.answer === -1)
                        let correctAnswered = ascVoters?.filter((asc) => parseInt(asc?.answer) === parseInt(poll?.pollAnswer))
                        let wrongAnswered = ascVoters?.filter((asc) => parseInt(asc?.answer) !== parseInt(poll?.pollAnswer) && parseInt(asc?.answer) !== -1)
                        let counter = 0;
                        return (
                            <Box
                                style={{
                                    marginBottom: "10px",
                                    borderRadius: "26px",
                                    boxShadow: "4px 6px 15px 0 #00000026",
                                }}
                                key={i}
                                p="4"
                                borderRadius="10px"
                                borderWidth="1px"
                                borderColor="border.disabled"
                                color="fg.disabled"
                                background="#EFF3F5"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#797DFA"
                                    }}>
                                        Poll {filtredPoll?.length - i}:
                                    </span>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "15px",
                                        }}
                                    >
                                        {poll?.pollStatus === "Ready" && (
                                            <Button
                                                disabled={_checkLivePoll()}
                                                onClick={() => handleStartPoll(poll)}
                                                style={{
                                                    background: "#00AC65",
                                                    color: "white",
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    padding: "9px 20px",
                                                    height: "auto",
                                                    borderRadius: "5px"
                                                }}
                                            >
                                                Start Poll
                                            </Button>
                                        )}
                                        {
                                            poll?.startTime &&
                                                poll?.pollTime?.length > 0 &&
                                                poll?.pollStatus === "Start" ?
                                                <Tag
                                                    style={{
                                                        background: "#FFA500",
                                                        color: "white",
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        padding: "9px 25px",
                                                        height: "auto",
                                                        borderRadius: "5px"
                                                    }}
                                                > {<Timer startTime={poll.startTime} duration={poll.pollTime} poll={poll} handleCompletedPoll={handleCompletedPoll} />}
                                                </Tag>
                                                : null
                                        }
                                        {poll?.pollStatus === "Start" && (
                                            <Tag
                                                style={{
                                                    background: "#797DFA",
                                                    color: "white",
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    padding: "9px 25px",
                                                    height: "auto",
                                                    borderRadius: "5px"
                                                }}
                                            >
                                                Live
                                            </Tag>
                                        )}
                                        {(poll?.pollStatus === "Start" || poll?.pollStatus === "Complete") && (
                                            <Button
                                                onClick={() => handleEndPoll(poll)}
                                                style={{
                                                    background: "red",
                                                    color: "white",
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    padding: "9px 20px",
                                                    height: "auto",
                                                    borderRadius: "5px"
                                                }}
                                            >
                                                End Poll
                                            </Button>
                                        )}
                                        {poll?.pollStatus === "End" && (
                                            <Tag
                                                style={{
                                                    background: "grey",
                                                    color: "white",
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    padding: "9px 25px",
                                                    height: "auto",
                                                    borderRadius: "5px"
                                                }}
                                            >
                                                Ended
                                            </Tag>
                                        )}
                                        {
                                            (poll?.pollStatus === "Ready" || poll?.pollStatus === "Start") &&
                                            <EditIcon style={{ cursor: "pointer" }} onClick={() => _editPoll(poll)} />
                                        }
                                    </div>
                                </div>
                                <Divider
                                    style={{ marginTop: "10px", marginBottom: "10px" }}
                                />
                                <div>
                                    <p style={{
                                        marginTop: "15px",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#1F1F39",
                                        lineHeight: "1.3em"
                                    }}
                                    >
                                        <b>Q : </b>
                                        {poll?.pollQuestion}
                                    </p>
                                    <div style={{ marginTop: "20px" }}>
                                        {poll?.pollOptions &&
                                            poll?.pollOptions.map((opt, i) => {
                                                let ans = false;
                                                if (parseInt(poll?.pollAnswer) === opt.key) {
                                                    ans = true;
                                                }
                                                return (
                                                    <div
                                                        key={i + 1}
                                                        style={{
                                                            marginBottom: "10px",
                                                            background: `linear-gradient(to right,#E0ECF2 ${_calOptionPercentage(poll?.voterDetails?.length, opt?.voterDetails?.length)}%,#fff 0)`,
                                                            display: "flex",
                                                            gap: 15,
                                                            borderRadius: "50px",
                                                            border: "1px solid #E8E8E8",
                                                            padding: "5px",
                                                            color: "#616165",
                                                            fontSize: "14px",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <span style={{
                                                            display: "flex",
                                                            width: 33,
                                                            height: 32,
                                                            borderRadius: "50px",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            backgroundColor: ans == true ? "#797DFA" : "transparent",
                                                            color: "#000",
                                                            border: "1px solid",
                                                            borderColor: ans == true ? "#797DFA" : "#E8E8E8",

                                                        }}>
                                                            {i + 1}
                                                        </span>
                                                        <span style={{
                                                            marginTop: "4px",
                                                            width: "calc(100% - 113px)",
                                                        }}>
                                                            {opt.value}
                                                        </span>
                                                        <span style={{
                                                            display: "inline-block",
                                                            width: "60px",
                                                            textAlign: "center",
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            color: "#797DFA",
                                                            marginTop: 4
                                                        }}
                                                        >
                                                            {poll?.pollStatus !== "Ready" ? _calOptionPercentage(poll?.voterDetails?.length, opt?.voterDetails?.length) + "%" : null}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    {
                                        (poll?.pollStatus === "End" || poll?.pollStatus === "Complete") ?
                                            <>
                                                <div style={{ marginTop: 15 }}>
                                                    <p style={{
                                                        color: "#ABABAB",
                                                        fontWeight: 600,
                                                        textAlign: "center",
                                                        fontSize: "14px",
                                                        letterSpacing: ".5px",
                                                    }}>Leaderboard</p>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "15px 20px",
                                                        borderRadius: "20px",
                                                        background: "#fff",
                                                        width: "45%",
                                                        maxWidth: "70%",
                                                        margin: "12px auto 0"
                                                    }}>
                                                        <div style={{ marginRight: "20px" }}>
                                                            <Image src="/images/leaderBoardIcon.png" width="25px" />
                                                        </div>
                                                        {
                                                            ascVoters?.length > 0 ?
                                                                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                                    <p style={{ color: "1F1F39", fontSize: "13px", fontWeight: "600", textTransform: "capitalize", margin: "0 " }}>
                                                                        1. {correctAnswered?.[0]?.userBean?.name || " --"}
                                                                    </p>
                                                                    <p style={{ color: "1F1F39", fontSize: "13px", fontWeight: "600", textTransform: "capitalize", margin: "0 " }}>
                                                                        2. {correctAnswered?.[1]?.userBean?.name || " --"}
                                                                    </p>
                                                                    <p style={{ color: "1F1F39", fontSize: "13px", fontWeight: "600", textTransform: "capitalize", margin: "0 " }}>
                                                                        3. {correctAnswered?.[2]?.userBean?.name || " --"}
                                                                    </p>
                                                                </div>
                                                                : <p style={{ color: "#00000063", fontSize: 14, textAlign: "center", width: "150px" }}>No data found!</p>
                                                        }
                                                    </div>
                                                </div>

                                                {/* complete leaderboard */}
                                                {
                                                    (correctAnswered?.length > 0 || wrongAnswered?.length > 0 || notAnswered?.length > 0) &&
                                                    <>
                                                        <div style={{ display: "flex", justifyContent: "end", marginTop: "-40px" }}>
                                                            <Button
                                                                style={{ fontSize: 14, fontWeight: 500, color: "#797DFA" }}
                                                                onClick={() => activeLeaderBoards?.[i] ? setActiveLeaderBoard({ ...activeLeaderBoards, [i]: false }) : setActiveLeaderBoard({ ...activeLeaderBoards, [i]: true })}>
                                                                More &nbsp; <AiOutlineArrowDown />
                                                            </Button>
                                                        </div>
                                                        {
                                                            activeLeaderBoards?.[i] &&
                                                            <Table className="leaderBoardTable" variant="simple">
                                                                <Thead>
                                                                    <Tr>
                                                                        <Th>Place</Th>
                                                                        <Th>Name</Th>
                                                                        <Th>Answer</Th>
                                                                        <Th>Time</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {correctAnswered.map((voter, index) => {
                                                                        counter += 1;
                                                                        return (
                                                                            <Tr key={voter?.userBean?.id}>
                                                                                <Td style={{ fontWeight: "600" }}>{counter}</Td>
                                                                                <Td>{voter?.userBean?.name}</Td>
                                                                                <Td style={{ color: "#000", padding: "0 30px" }}>{voter?.answer !== -1 ? voter?.answer : "N/A"}</Td>
                                                                                <Td>
                                                                                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                                                        <span>
                                                                                            <Image src="/images/timerIcon.svg" width={15} />
                                                                                        </span>
                                                                                        <span style={{ fontSize: 14, fontWeight: 500, color: "#797DFA", marginTop: "3px" }}>
                                                                                            {_findVoterTimestamp(poll?.pollCreatedDate, voter?.userBean?.pollAnswerDateTime)}
                                                                                        </span>
                                                                                    </div>
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    })}
                                                                    {wrongAnswered.map((voter, index) => {
                                                                        counter += 1;
                                                                        return (
                                                                            <Tr key={voter?.userBean?.id} style={{ background: "#ffd3d3" }}>
                                                                                <Td style={{ fontWeight: "600" }}>{counter}</Td>
                                                                                <Td>{voter?.userBean?.name}</Td>
                                                                                <Td style={{ color: "#000", padding: "0 30px" }}>{voter?.answer !== -1 ? voter?.answer : "N/A"}</Td>
                                                                                <Td>
                                                                                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                                                        <span>
                                                                                            <Image src="/images/timerIcon.svg" width={15} />
                                                                                        </span>
                                                                                        <span style={{ fontSize: 14, fontWeight: 500, color: "#797DFA", marginTop: "3px" }}>
                                                                                            {_findVoterTimestamp(poll?.pollCreatedDate, voter?.userBean?.pollAnswerDateTime)}
                                                                                        </span>
                                                                                    </div>
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    })}
                                                                    {notAnswered.map((voter, index) => {
                                                                        counter += 1;
                                                                        return (
                                                                            <Tr key={voter?.userBean?.id} style={{ background: "#d4d4d4" }}>
                                                                                <Td style={{ fontWeight: "600" }}>{counter}</Td>
                                                                                <Td>{voter?.userBean?.name}</Td>
                                                                                <Td style={{ color: "#000", padding: "0 30px" }}>{voter?.answer}</Td>
                                                                                <Td>
                                                                                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                                                        <span>
                                                                                            <Image src="/images/timerIcon.svg" width={15} />
                                                                                        </span>
                                                                                        <span style={{ fontSize: 14, fontWeight: 500, color: "#797DFA", marginTop: "3px" }}>
                                                                                            {_findVoterTimestamp(poll?.pollCreatedDate, voter?.userBean?.pollAnswerDateTime)}
                                                                                        </span>
                                                                                    </div>
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    })}
                                                                </Tbody>
                                                            </Table>
                                                        }
                                                    </>
                                                }
                                            </>
                                            : null
                                    }
                                </div>
                            </Box>
                        );
                    })}
            </div>
            <div style={{
                position: "sticky",
                background: "#f6f6f6",
                bottom: "0",
                paddingBottom: "10px"
            }}>
                <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        onClick={addNewPoll}
                        style={{
                            background: "#00AC65",
                            color: "white",
                            fontWeight: 500,
                            height: "auto",
                            padding: "5px 15px 7px",
                            borderRadius: "5px"
                        }}
                    >
                        + New Poll
                    </Button>
                </div>
            </div>

            {/* Poll Modal */}
            {pollModalOpen && (
                <PollCreateForm
                    liveMessages={liveMessages}
                    addToAllRooms={addToAllRooms}
                    pollModalOpen={pollModalOpen}
                    setPollModalOpen={setPollModalOpen}
                    handlePollSubmit={handleEditPollSubmit}
                    handleAddtoAllRooms={handleAddtoAllRooms}
                    pollDetails={currentPollDetials}
                    editPoll={currentPollDetials ? true : false}
                />
            )}
        </div>
    )
}

const LiveClassDoubts = ({ liveMessages }) => {

    const dispatch = useDispatch();
    const [singleChatData, setSingleChatData] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const {
        user,
        teacherDoubts,
        updateDoubtStatus,
        getTeacherDoubtsStatus,
    } = useSelector((s) => ({
        user: s.user.user,
        teacherDoubts: s.doubts.teacherDoubts,
        updateDoubtStatus: s.doubts.updateDoubtStatus,
        getTeacherDoubtsStatus: s.doubts.getTeacherDoubtsStatus,
    }));

    useEffect(() => {
        if (updateDoubtStatus && getTeacherDoubtsStatus === "SUCCESS") {
            let updatededChatData = teacherDoubts?.find((td) => td?.itemId === singleChatData?.itemId)
            if (updatededChatData) {
                setSingleChatData(updatededChatData);
            }
            dispatch(resetUpdateDoubtStatus());
        }

    }, [getTeacherDoubtsStatus, updateDoubtStatus])

    useEffect(() => {
        let ids = liveMessages?.map((item) => item?._id)
        ids = ids?.join(',')
        if (updateDoubtStatus === "SUCCESS" && user?._id) {
            dispatch(getTeacherDoubtsAction({ page: currentPage, staff: user?._id, itemId: ids }));
        }
    }, [updateDoubtStatus])

    useEffect(() => {
        let ids = liveMessages?.map((item) => item?._id)
        ids = ids?.join(',')
        dispatch(getTeacherDoubtsAction({ page: currentPage, staff: user?._id, itemId: ids }));
    }, [user?._id, currentPage])

    return (
        <div style={{ position: "relative", height: "100%", borderTop: "1px solid #000" }}>
            {/* Top Section */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 50px" }}>
                <div>
                    {
                        singleChatData?._id &&
                        <Button onClick={() => setSingleChatData(null)}>
                            <AiOutlineArrowLeft />
                        </Button>
                    }
                </div>
                <div style={{ display: "flex", gap: 8 }}>
                    <Image src="/images/usersIcon.svg" width={15} />
                    <b style={{ fontSize: 14 }}>: {teacherDoubts?.length || 0}</b>
                </div>
            </div>

            {
                singleChatData ?
                    <LiveDoubtChat singleChatData={singleChatData} />
                    :

                    <div style={{ padding: "0 70px 10px" }}>
                        {teacherDoubts?.length > 0 ?

                            teacherDoubts?.map((doubtParent, i) => (
                                <DoubtItem onPress={setSingleChatData} d={doubtParent} i={i + 1} />
                            ))
                            :
                            <div style={{ color: "#0000004d", fontSize: "14px", height: "300px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Image src="/images/noDoubtsFoundIcon.png" width={"35%"} />
                            </div>
                        }
                    </div>

            }

        </div>
    )
}

const LiveDoubtChat = ({ singleChatData }) => {
    const dispatch = useDispatch();
    const [replyMessage, setReplyMessage] = useState("");
    const [replyMessageFile, setReplyMessageFile] = useState(null);

    const {
        user,
        uploadFileStatus,
        file,
    } = useSelector((s) => ({
        user: s.user.user,
        uploadFileStatus: s.website.uploadFileStatus,
        file: s.website.file,
    }));

    useEffect(() => {
        if (uploadFileStatus === "SUCCESS") {
            setReplyMessageFile(file)
            dispatch(resetFileAction())
        }
    })

    const _resolveDoubt = () => {
        if (singleChatData?.doubt?.[singleChatData?.doubt.length - 1]._id && user._id) {
            let payload = {
                queryId: singleChatData?.doubt?.[singleChatData?.doubt.length - 1]._id,
                message: replyMessage || "",
                staff: user._id
            };
            if (replyMessageFile?.fileName?.length > 0) {
                const formatted = {
                    "mimeType": replyMessageFile?.type,
                    "name": replyMessageFile?.fileName,
                    "size": replyMessageFile?.size,
                    "url": replyMessageFile?.url
                }
                payload.files = [formatted]
            }
            if (replyMessage.trim()?.length > 0 || replyMessageFile?.fileName?.length > 0) {
                dispatch(updateDoubtAction(payload))
                setReplyMessageFile(null)
                setReplyMessage("")
            }
            else {
                setReplyMessage("")
            }
        }
        else {
            toast({
                title: `Something went wrong!`,
                status: "warning",
                isClosable: true,
                position: "top",
            });
        }
    }
    const handleFileChange = (event) => {
        let item = { file: event.target.files?.[0] };
        let form_data = new FormData();

        for (let key in item) {
            form_data.append(key, item[key]);
        }
        dispatch(uploadFileAction(form_data));
    };
    return (
        <>
            <div style={{ padding: "0 70px 10px" }}>
                {
                    singleChatData?.doubt?.length > 0 ?
                        singleChatData?.doubt?.map((doubt) => (
                            <>
                                {
                                    doubt?.doubt_query &&
                                    <div className="studentMsg" style={{ minWidth: "70%", maxWidth: "80%", margin: "20px 0 20px " }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <div style={{ width: 35, height: 35, borderRadius: 35, overflow: "hidden", border: "2px solid #797DFA" }}>
                                                    <Image src="/images/chatUserIcon.png" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                                </div>
                                                <Text style={{ fontSize: "12px", color: "#2D2D2D" }}>{singleChatData?.userId?.name}</Text>
                                            </div>
                                            <Text style={{ fontSize: "12px", color: "#616165" }}>{moment(doubt?.createdAt).format('hh:mm a')}</Text>
                                        </div>
                                        {
                                            doubt?.doubt_query ?
                                                <div style={{ padding: "8px 15px", borderRadius: "0 10px 10px 10px", border: "1px solid #ABABAB4D", width: "max-content", maxWidth: "85%", marginLeft: "15%" }}>
                                                    <p>
                                                        {doubt?.doubt_query}
                                                    </p>
                                                </div>
                                                : null
                                        }
                                        {
                                            doubt?.files?.length > 0 ?
                                                doubt.files.map((file) => (
                                                    <div style={{ padding: "8px 15px", borderRadius: "0 10px 10px 10px", border: "1px solid #ABABAB4D", width: "max-content", maxWidth: "85%", margin: "10px auto 10px 15%" }}>
                                                        {
                                                            file?.mimeType?.includes("image") ?
                                                                <Image src={file?.url} style={{ maxWidth: "100%", margin: "0 0 10px", maxHeight: "200px" }} />
                                                                :
                                                                file?.mimeType?.includes("audio") ?
                                                                    <audio controls>
                                                                        <source src={file?.url} type={file?.mimeType} />
                                                                        Your browser does not support the audio element.
                                                                    </audio>
                                                                    :
                                                                    file?.mimeType?.includes("video") ?
                                                                        <video controls style={{ maxHeight: "400px" }}>
                                                                            <source src={file?.url} type={file?.mimeType} />
                                                                            Your browser does not support the video element.
                                                                        </video>
                                                                        :
                                                                        file?.mimeType?.includes("pdf") ?
                                                                            <a href={file?.url} target="_blank">
                                                                                <HStack style={{ gap: "5px" }}>
                                                                                    <Image src="/images/pdfFileIcon.png" width={30} />
                                                                                    <Text
                                                                                        color="#000"
                                                                                        fontSize="16px"
                                                                                        noOfLines={1}
                                                                                        overflow="hidden"
                                                                                        textOverflow="ellipsis"
                                                                                        width={"calc(100% - 95px)"}
                                                                                        style={{ margin: "0", padding: "0" }}
                                                                                    >
                                                                                        {file?.name}
                                                                                    </Text>
                                                                                    <Text
                                                                                        color="#16B364"
                                                                                        fontSize="13px"
                                                                                        fontWeight="500"
                                                                                        width={55}
                                                                                        style={{ margin: "0", padding: "0" }}
                                                                                    >
                                                                                        {parseFloat((file?.size / 1048576).toFixed(2))} Mb
                                                                                    </Text>
                                                                                </HStack>
                                                                            </a>
                                                                            : null
                                                        }
                                                    </div>
                                                ))


                                                : null
                                        }
                                    </div>
                                }
                                {
                                    doubt?.response?.map(res => (
                                        <div className="mentorMsg" style={{ minWidth: "70%", maxWidth: "80%", margin: "20px 0 20px auto" }}>

                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                                <Text style={{ fontSize: "12px", color: "#616165" }}>{moment(res?.createdAt).format('hh:mm a')}</Text>

                                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                    <Text style={{ fontSize: "12px", color: "#2D2D2D" }}>{res?.staff === user?._id ? "You" : res?.staff}</Text>

                                                    <div style={{ width: 35, height: 35, borderRadius: 35, overflow: "hidden", border: "2px solid #797DFA" }}>
                                                        <Image src="/images/chatUserIcon.png" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                                    </div>
                                                </div>

                                            </div>

                                            {
                                                res?.files?.length > 0 ?
                                                    res.files.map((file) => (
                                                        <div style={{ padding: "8px 15px", borderRadius: "10px 0 10px 10px", border: "1px solid #ABABAB4D", width: "max-content", maxWidth: "85%", margin: "10px 15% 10px auto" }}>
                                                            {
                                                                file?.mimeType?.includes("image") ?
                                                                    <Image src={file?.url} style={{ maxWidth: "100%", margin: "0 0 10px", maxHeight: "200px" }} />
                                                                    :
                                                                    file?.mimeType?.includes("audio") ?
                                                                        <audio controls>
                                                                            <source src={file?.url} type={file?.mimeType} />
                                                                            Your browser does not support the audio element.
                                                                        </audio>
                                                                        :
                                                                        file?.mimeType?.includes("video") ?
                                                                            <video controls style={{ maxHeight: "400px" }}>
                                                                                <source src={file?.url} type={file?.mimeType} />
                                                                                Your browser does not support the video element.
                                                                            </video>
                                                                            :
                                                                            file?.mimeType?.includes("pdf") ?
                                                                                <a href={file?.url} target="_blank">
                                                                                    <HStack style={{ gap: "5px" }}>
                                                                                        <Image src="/images/pdfFileIcon.png" width={30} />
                                                                                        <Text
                                                                                            color="#000"
                                                                                            fontSize="16px"
                                                                                            noOfLines={1}
                                                                                            overflow="hidden"
                                                                                            textOverflow="ellipsis"
                                                                                            width={"calc(100% - 95px)"}
                                                                                            style={{ margin: "0", padding: "0" }}
                                                                                        >
                                                                                            {file?.name}
                                                                                        </Text>
                                                                                        <Text
                                                                                            color="#16B364"
                                                                                            fontSize="13px"
                                                                                            fontWeight="500"
                                                                                            width={55}
                                                                                            style={{ margin: "0", padding: "0" }}
                                                                                        >
                                                                                            {parseFloat((file?.size / 1048576).toFixed(2))} Mb
                                                                                        </Text>
                                                                                    </HStack>
                                                                                </a>
                                                                                : null
                                                            }
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                            {
                                                res?.message?.length > 0 ?
                                                    <div style={{ padding: "8px 15px", borderRadius: "10px 0 10px 10px", border: "1px solid #ABABAB4D", width: "max-content", maxWidth: "85%", margin: "0 15% 10px auto" }}>
                                                        <p>
                                                            {res?.message}
                                                        </p>
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                    ))
                                }
                            </>
                        ))
                        :
                        <div style={{ color: "#0000004d", fontSize: "14px", height: "300px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            No doubts found!
                        </div>
                }
            </div>
            {/* Bottom Send Message section */}
            <div style={{ width: "100%", display: "flex", alignItems: "center", position: "sticky", bottom: 0, left: 0, justifyContent: "space-between", padding: "10px 40px 10px 20px", background: "#f6f6f6", boxShadow: "0 0px 1px 0 #c0c0c0" }}>
                <div style={{ width: 50, height: 50, borderRadius: 50, overflow: "hidden", border: "2px solid #797DFA" }}>
                    <Image src="/images/chatUserIcon.png" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                </div>
                <div style={{ display: "flex", alignItems: "center", border: "1px solid #ABABAB", borderRadius: "10px", padding: "6px", width: "calc(100% - 60px)" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderRight: "1px solid #ABABAB", maxWidth: "25%", padding: "6px 10px 6px 0" }}>

                        <Menu placement="top-end">
                            <MenuButton>
                                <div style={{ width: "30px", height: "30px", borderRadius: "30px", background: "#797DFA", overflow: "hidden", padding: "8px" }}>
                                    <Image src="/images/imageFrame.svg" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                </div>
                            </MenuButton>
                            <MenuList style={{ padding: "10px" }}>
                                <HStack spacing={5} >
                                    <div>
                                        <Input
                                            type="file"
                                            accept="video/*"
                                            onChange={handleFileChange}
                                            display="none"
                                            id="video-upload"
                                        />
                                        <label htmlFor="video-upload" >
                                            <Button as="span" style={{ width: "40", height: "40", borderRadius: "40", background: "transparent", overflow: "hidden", padding: "0", cursor: "pointer" }}>
                                                <Image src="/images/videoButtonIcon.svg" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                            </Button>
                                        </label>
                                    </div>
                                    <div>
                                        <Input
                                            type="file"
                                            accept="audio/*"
                                            onChange={handleFileChange}
                                            display="none"
                                            id="audio-upload"
                                        />
                                        <label htmlFor="audio-upload" >
                                            <Button as="span" style={{ width: "40", height: "40", borderRadius: "40", background: "transparent", overflow: "hidden", padding: "0", cursor: "pointer" }}>
                                                <Image src="/images/audioButtonIcon.svg" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                            </Button>
                                        </label>
                                    </div>
                                    <div>
                                        <Input
                                            type="file"
                                            accept=".pdf"
                                            onChange={handleFileChange}
                                            display="none"
                                            id="file-upload"
                                        />
                                        <label htmlFor="file-upload" >
                                            <Button as="span" style={{ width: "40", height: "40", borderRadius: "40", background: "transparent", overflow: "hidden", padding: "0", cursor: "pointer" }}>
                                                <Image src="/images/documentbuttonIcon.svg" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                            </Button>
                                        </label>
                                    </div>
                                    <div>
                                        <Input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            display="none"
                                            id="image-upload"
                                        />
                                        <label htmlFor="image-upload" >
                                            <Button as="span" style={{ width: "40", height: "40", borderRadius: "40", background: "transparent", overflow: "hidden", padding: "0", cursor: "pointer" }}>
                                                <Image src="/images/imageButtonIcon.svg" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                            </Button>
                                        </label>
                                    </div>

                                </HStack>
                            </MenuList>
                        </Menu>
                        {replyMessageFile && (
                            <Box maxWidth="calc(100% - 40px)">
                                <Text
                                    color="#0000004d"
                                    fontSize="13px"
                                    noOfLines={1}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                >
                                    {replyMessageFile?.fileName}
                                </Text>
                                <Text
                                    color="#0000004d"
                                    fontSize="13px"
                                    noOfLines={1}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                >
                                    {parseFloat((replyMessageFile?.size / 1048576).toFixed(2))} Mb
                                </Text>
                            </Box>
                        )}
                    </div>
                    <div style={{ display: "flex", alignItems: "center", width: "100%", padding: "0 5px" }}>
                        <input
                            value={replyMessage}
                            onChange={(e) => setReplyMessage(e.target.value)}
                            type="text" style={{ border: "none", padding: "10px", width: "100%", background: "transparent", outline: "none" }}
                            onKeyDown={(e) => { if (e.key === "Enter") { _resolveDoubt() } }}
                        />
                        <Button isLoading={uploadFileStatus === "FETCHING"} style={{ display: "inline-block", width: "30px", height: "30px", borderRadius: "30px", padding: "0" }} onClick={_resolveDoubt}>
                            <Image src="/images/sendMessageButotn.png" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}



